#DeliveryComplaintIndex {
  .submitted {
  }

  .rejected,
  .purchase-canceled {
    background-color: #eee;
  }

  .accepted {
    background-color: #efe;
  }
}